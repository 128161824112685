<template>
  <div>
    <div
      class="flex sm:flex-col items-center sm:items-start justify-between my-6 sm:space-y-4"
    >
      <div class="flex space-x-4">
        <div class="flex items-center space-x-2">
          <img
            src="/images/DossierPromoteur/anonymous-icon.png"
            class="w-4 h-4"
          />
          <div class="text-xs">Dossier anonyme</div>
        </div>
        <div
          class="flex items-center"
          v-for="(item, key) in status"
          :key="item + '-type'"
        >
          <div
            :class="`rounded-full w-3 h-3 bg-promy-${getStatusColor(key)} mr-1`"
          />
          <p class="text-xs text-promy-gray-600 leading-none">{{ item }}</p>
        </div>
      </div>
      <button
        v-if="$can('consultation,dossier_promoteur')"
        @click="downloadStatCSV"
        class="flex items-center justify-center px-4 py-2 text-sm font-extrabold text-white uppercase border-2 rounded-full cursor-pointer bg-promy-green-300 border-promy-green-300 font-main sm:w-full"
      >
        Exporter statistiques
      </button>
    </div>
    <div
      class="xl:h-50-screen xl:overflow-y-auto liste-dossier"
      id="list_holder"
    >
      <template v-if="isFetching && current_page == 1">
        <div class="flex justify-center w-full mt-24">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="dossierPromoteur.length > 0">
          <pro-card>
            <pro-table-row :isHeader="true">
              <pro-table-cell class="w-[3%]">
                <Checkbox
                  v-model="checked_all"
                  :val="checked_all"
                  id="all_terrains_archive"
                  class="mb-6"
                />
              </pro-table-cell>
              <pro-table-header-cell
                v-for="(sort, index) in sort_list"
                :key="index"
                :class="sort.classes"
                :sortingBy="sorting_by"
                @setSortingBy="setSortingBy"
                @orderBy="orderBy"
                :hasSort="true"
                :title="sort.title"
                :sortKey="sort.sortKey"
              />
            </pro-table-row>
            <pro-table-row
              v-for="(item, index) in dossierPromoteur"
              :key="index"
              :pulsedBorder="{
                hasPulsedBorder: item.has_new_offre,
                color: 'promy-blue',
              }"
              class="relative"
            >
              <pro-table-cell
                :is_checkbox="true"
                label="Séléctionner"
                class="w-[3%] lg:w-full"
              >
                <Checkbox
                  v-model="item.checked"
                  :val="item.checked"
                  :id="`region_${item.id}`"
                  class="mb-6"
                />
              </pro-table-cell>
              <pro-table-cell
                label="Statut"
                class="w-[6%] lg:w-full flex justify-center"
              >
                <div class="flex items-center">
                  <div
                    :class="`rounded-full mr-1 w-3 h-3 bg-promy-${getStatusColor(
                      item.status,
                    )}`"
                  />
                  <img
                    v-if="item.is_anonymous"
                    src="/images/DossierPromoteur/anonymous-icon.png"
                    class="w-4 h-4 mx-1"
                  />
                  <div class="mt-1" v-if="item.status == 6">
                    <div v-if="item.is_not_visible_when_vendu">
                      <svg
                        width="25px"
                        height="25px"
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m174.83 87.328c-4.668 0-9.1445 1.8672-12.43 5.1836-3.2852 3.3164-5.1094 7.8086-5.0664 12.477 0.042969 4.6719 1.9492 9.1289 5.293 12.383l58.344 58.344c-69.168 38.223-112.18 93.414-112.18 93.414h0.003907c-2.4492 3.0938-3.7852 6.9258-3.7852 10.871s1.3359 7.7773 3.7852 10.871c0 0 100.01 129.13 241.2 129.13 34.68 0 66.828-7.8281 95.668-19.586l66.957 66.957v0.003906c3.2617 3.3828 7.7461 5.3164 12.445 5.3633 4.6992 0.042969 9.2188-1.8047 12.539-5.125 3.3242-3.3242 5.1719-7.8438 5.125-12.543-0.042969-4.6992-1.9766-9.1836-5.3594-12.441l-58.344-58.344c69.168-38.223 112.18-93.414 112.18-93.414h-0.003906c2.4492-3.0938 3.7852-6.9258 3.7852-10.871s-1.3359-7.7773-3.7852-10.871c0 0-100.01-129.13-241.2-129.13-34.68 0-66.828 7.8281-95.668 19.586l-66.961-66.961c-3.293-3.3867-7.8164-5.2969-12.543-5.2969zm175.18 87.672c108.06 0 186.62 87.152 202.14 105-9.9492 11.445-45.922 51.324-98.883 78.512l-43.203-43.203c6.1758-10.418 9.9453-22.402 9.9453-35.309 0-38.453-31.547-70-70-70-12.906 0-24.887 3.7695-35.309 9.9453l-33.152-33.152c21.078-7.1367 43.957-11.793 68.461-11.793zm-103.26 26.488 43.203 43.203c-6.1758 10.418-9.9453 22.402-9.9453 35.309 0 38.453 31.547 70 70 70 12.906 0 24.887-3.7695 35.309-9.9453l33.152 33.152c-21.078 7.1367-43.957 11.793-68.461 11.793-108.06 0-186.62-87.152-202.14-105 9.9492-11.445 45.922-51.324 98.883-78.512zm103.26 43.512c19.535 0 35 15.461 35 35 0 3.1289-0.55469 6.082-1.3008 8.957l-42.656-42.656c2.8711-0.74609 5.8281-1.3008 8.957-1.3008zm-33.703 26.047 42.656 42.656c-2.8711 0.74609-5.8281 1.3008-8.957 1.3008-19.535 0-35-15.461-35-35 0-3.1289 0.55469-6.082 1.3008-8.957z"
                        />
                      </svg>
                    </div>
                    <div v-else>
                      <svg
                        width="25px"
                        height="25px"
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill-rule="evenodd">
                          <path
                            d="m350 198.33c-45.094 0-81.668 36.68-81.668 81.668 0 44.996 36.672 81.668 81.668 81.668s81.668-36.672 81.668-81.668-36.672-81.668-81.668-81.668zm-58.332 81.668c0-32.121 26.145-58.332 58.332-58.332 32.109 0 58.332 26.223 58.332 58.332s-26.223 58.332-58.332 58.332-58.332-26.223-58.332-58.332z"
                          />
                          <path
                            d="m350.28 128.33c-73.867 0-132.04 36.527-171.21 72.117-19.652 17.855-34.766 35.684-44.977 49.051-5.1133 6.6992-9.0156 12.297-11.656 16.258-1.3242 1.9844-2.332 3.5547-3.0234 4.6523-0.34375 0.54688-0.60937 0.97656-0.79297 1.2812l-0.21484 0.35547-0.066406 0.10547-0.019532 0.035156c0 0.003906-0.011718 0.019531 10.016 5.9844l-10.027-5.9648-3.5039 5.8906 3.4375 5.9258 10.094-5.8516c-10.094 5.8516-10.098 5.8477-10.094 5.8516l0.011718 0.023437 0.019532 0.03125 0.0625 0.10938 0.21484 0.36328c0.18359 0.30859 0.44922 0.75 0.79297 1.3086 0.6875 1.125 1.6953 2.7344 3.0156 4.7656 2.6406 4.0625 6.5391 9.8047 11.645 16.672 10.203 13.715 25.309 32.004 44.953 50.32 39.148 36.508 97.348 74.051 171.32 74.051 73.875 0 131.91-36.531 170.95-72.133 19.582-17.855 34.625-35.688 44.785-49.062 5.0859-6.6953 8.9648-12.297 11.59-16.262 1.3164-1.9805 2.3203-3.5508 3.0039-4.6484 0.34375-0.55078 0.60938-0.98047 0.79297-1.2812l0.21484-0.35547 0.0625-0.10547 0.019531-0.035156c0.003906-0.003906 0.011719-0.023437-10.031-5.957l10.043 5.9336 3.4648-5.8594-3.3984-5.8984-10.109 5.8242c10.109-5.8242 10.113-5.8164 10.109-5.8242l-0.011719-0.019531-0.019531-0.03125-0.0625-0.10938-0.21484-0.36328c-0.18359-0.30859-0.44531-0.75-0.78516-1.3125-0.68359-1.1211-1.6875-2.7344-3-4.7656-2.625-4.0625-6.5-9.8086-11.582-16.676-10.148-13.715-25.188-32.008-44.762-50.332-39.008-36.512-97.078-74.062-171.06-74.062zm-197.55 165.04c-4.6367-6.2344-8.1758-11.438-10.578-15.113 2.3984-3.5742 5.9062-8.5938 10.488-14.594 9.5742-12.535 23.75-29.254 42.121-45.941 36.867-33.496 89.672-66.055 155.52-66.055 65.738 0 118.36 33.363 155.12 67.762 18.305 17.137 32.418 34.301 41.949 47.176 4.6172 6.2461 8.1445 11.457 10.531 15.137-2.3828 3.5742-5.8789 8.6055-10.445 14.621-9.5195 12.531-23.625 29.242-41.926 45.93-36.719 33.484-89.383 66.039-155.23 66.039-65.742 0-118.52-33.371-155.41-67.777-18.379-17.141-32.562-34.309-42.145-47.184z"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </pro-table-cell>
              <pro-table-cell label="Avancement" class="w-[8%] lg:w-full">
                <span v-if="item.avancement"> {{ item.avancement }} % </span>
              </pro-table-cell>
              <pro-table-cell label="N° dossier" class="w-[9%] lg:w-full">
                {{ item.dossier_number }}
              </pro-table-cell>
              <pro-table-cell label="Commune" class="w-[16%] lg:w-full">
                {{ item.commune }}
              </pro-table-cell>
              <pro-table-cell label="CP" class="w-[6%] lg:w-full">
                {{ item.code_postal }}
              </pro-table-cell>
              <pro-table-cell label="Ca potentiel" class="w-[8.5%] lg:w-full">
                <span class="font-extrabold"
                  >€ {{ item.ca_potentiel | formatEUR }}</span
                >
              </pro-table-cell>
              <pro-table-cell label="Surf." class="w-[7%] lg:w-full">
                {{ item.surface_terrain | roundDouble }} M²
              </pro-table-cell>
              <pro-table-cell label="Type" class="flex w-[7.5%] lg:w-full">
                <div>
                  {{ item.type_de_projet ? item.type_de_projet : 'N.C' }}
                </div>
              </pro-table-cell>
              <pro-table-cell label="Offres" class="w-[6.7%] lg:w-full">
                {{ item.nombre_offre ? item.nombre_offre : 0 }}
              </pro-table-cell>
              <pro-table-cell label="Vues" class="w-[5.5%] lg:w-full">
                <PromoteurSeens
                  :vueDossierPromoteur="item.vue_dossier_promoteur"
                  :seensBy="item.seens_by"
                />
              </pro-table-cell>
              <pro-table-cell label="Temps restant" class="w-[13%] lg:w-full">
                ------
              </pro-table-cell>
              <!-- <pro-table-cell class="w-[11.5%]">
                {{ item.responsable ? item.responsable : 'N.C' }}
              </pro-table-cell> -->
              <pro-table-cell class="w-[3.5%] lg:w-full px-0">
                <div class="flex items-center space-x-4 absolute top-3 right-4">
                  <generatePdfDossierPro :item="item"></generatePdfDossierPro>
                  <ToggleDropUp
                    :item="item"
                    :archivedStatus="archivedStatus"
                    possition="bottom: 25px; right: 25px"
                    @displayArchiveModal="displayModal"
                  />
                </div>
              </pro-table-cell>
            </pro-table-row>
            <isVisible :isFetching="isFetching" @isVisible="isVisible" />
          </pro-card>
        </template>
        <template v-else>
          <p
            class="flex justify-center w-full mt-24 text-lg font-extrabold text-promy-gray-650 font-main"
          >
            Il n'y a pas de dossier pour "{{ filters.search }}"
          </p>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import helpers from '@/mixins/helpers'
import { mapGetters } from 'vuex'
import PromoteurSeens from '@/components/DossierPromoteurs/PromoteurSeens'
import ToggleDropUp from './components/ToggleDropUp'
import generatePdfDossierPro from './components/generatePdfDossierPro'
import moment from 'moment'

export default {
  mixins: [helpers],
  components: {
    PromoteurSeens,
    ToggleDropUp,
    generatePdfDossierPro,
  },

  props: {
    filters: {
      type: Object,
      require: true,
    },
    sort_list: {
      type: Array,
      require: true,
    },
    reload_dossier_promoteur: {
      type: Boolean,
      require: true,
    },
    let_save_scroll_position: {
      type: Boolean,
      require: true,
    },
  },

  data() {
    return {
      status: {
        5: 'Commercialisation terminée',
        6: 'Suivi de la vente',
        7: 'Dossier clos',
      },
      isFetching: false,
      current_page: 1,
      last_page: 1,
      total_page: 1,
      dossierPromoteur: [],
      archivedStatus: [],
      sorting_by: '',
      checked_all: false,
      showAfterSecond: false,
    }
  },

  mounted() {
    this.scrollPositionSaved()
    this.showTimer()
  },

  created() {
    if (this.isDossiersPromoteurSaved) {
      Object.assign(this.$data, this.getDossiersPromoteur)
    } else {
      this.sorting_by = this.sort_list_terrains_by
      this.getDossierPromoteurs(
        this.filters,
        this.sort_list_terrains_by,
        this.sort_list_terrains_by_order,
      )
    }
  },

  watch: {
    filters: {
      deep: true,
      handler(filters) {
        this.searchAfterDebounce(filters)
      },
    },
    reload_dossier_promoteur(val) {
      if (val) {
        this.current_page = 1
        this.dossierPromoteur = []
        this.getDossierPromoteurs(
          this.filters,
          this.sort_list_terrains_by,
          this.sort_list_terrains_by_order,
        )
        this.$emit('dossierPromoteurReloaded')
      }
    },
    let_save_scroll_position(val) {
      if (val) {
        const dossiersPromoteursDOM = document.getElementById('list_holder')

        if (
          dossiersPromoteursDOM.scrollLeft !== 0 ||
          dossiersPromoteursDOM.scrollTop !== 0
        ) {
          this.$store.commit(
            'userNavigationState/SET_DOSSIERS_PROMOTEUR_SCROLL_POSITION',
            {
              left: dossiersPromoteursDOM.scrollLeft,
              top: dossiersPromoteursDOM.scrollTop,
            },
          )
        } else {
          this.$store.commit(
            'userNavigationState/SET_DOSSIERS_PROMOTEUR_SCROLL_POSITION',
            null,
          )
        }
      }
    },
    dossierPromoteur: {
      deep: true,
      handler(val) {
        this.$store.commit(
          'dossierpromoteur/SET_DOSSIER_PROMOTEUR_SELECTED',
          val,
        )
      },
    },
    checked_all() {
      if (this.checked_all)
        this.dossierPromoteur.map((terrain) => (terrain.checked = true))
      else this.dossierPromoteur.map((terrain) => (terrain.checked = false))
    },
  },

  methods: {
    searchAfterDebounce: _.debounce(function (filters) {
      this.current_page = 1
      this.dossierPromoteur = []
      this.getDossierPromoteurs(
        filters,
        this.sort_list_terrains_by,
        this.sort_list_terrains_by_order,
      )
    }, 800),

    getDossierPromoteurs(filters, key = null, order = null) {
      this.isFetching = true
      this.$emit('setFilter', filters)
      this.$http
        .get(`admin/dossier-promoteurs?page=${this.current_page}`, {
          params: {
            status: Object.keys(this.status),
            key: key,
            order: order,
            ...filters,
            departement_id: this.filters.departement?.id,
            region_id: this.filters.region?.id,
            page_size_state: this.getDossiersPromoteurCurrentPage,
            surface_terrain: this.filters.surface_terrain,
            ca_potentiel: this.filters.ca_potentiel,
            availabe_when_brouillon: this.filters.availabe_when_brouillon
              ? this.filters.availabe_when_brouillon
              : '',
            vendu: this.filters.vendu ? this.filters.vendu : '',
          },
        })
        .then((response) => {
          this.$emit('setDataMeta', { meta: response.data.meta })
          this.archivedStatus = response.data.meta.archived_status
          let tab = response.data.data.map((folder) => ({
            ...folder,
            checked: false,
          }))
          this.dossierPromoteur.push(...tab)
          this.dossier_promoteur_selected.map((terrain) => {
            this.dossierPromoteur.map((dossier) => {
              if (terrain.id === dossier.id) dossier.checked = true
            })
          })
          this.$store.commit(
            'dossierpromoteur/SET_LIST_ADMINS',
            response.data.meta.list_admins,
          )
          this.current_page = response.data.meta.current_page
          this.last_page = response.data.meta.last_page
          this.isFetching = false
        })
    },

    downloadStatCSV() {
      this.$http
        .get(`admin/dossier-promoteurs/export-stat`, {
          responseType: 'arraybuffer',
          params: {
            status: 5,
          },
        })
        .then((response) => {
          let blob = new Blob([response.data])
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob, {
            type: 'text/csv',
          })
          link.download = `Statistiques-MKP-Terminees-${moment(
            new Date(),
          ).format('DD-MM-YYYY')}.csv`
          link.click()
        })
        .catch((err) => {
          this.$toaster.warning("une erreur s'est produite")
        })
    },

    getStatusColor(status) {
      switch (status.toString()) {
        case '6':
          return 'bleu-100'
        case '5':
          return 'orange-100'
        case '7':
          return 'red'
      }
    },

    isVisible() {
      if (this.last_page === this.current_page) {
        return
      }
      this.current_page++
      this.getDossierPromoteurs(
        this.filters,
        this.sort_list_terrains_by,
        this.sort_list_terrains_by_order,
      )
    },

    setSortingBy(val) {
      this.sorting_by = val
    },

    orderBy(key, order) {
      this.sort_list_terrains_by = key
      this.sort_list_terrains_by_order = order
      this.current_page = 1
      this.dossierPromoteur = []
      this.getDossierPromoteurs(this.filters, key, order)
    },

    displayModal(id) {
      this.$emit('displayModal', id)
    },

    // feature add responsable to terrain
    // displayModalTransfer(id) {
    //   this.$emit('displayModalTransfer', id)
    // },

    scrollPositionSaved() {
      if (this.isScrollPositionSaved) {
        const dossiersPromoteursDOM = document.getElementById('list_holder')
        setTimeout(() => {
          dossiersPromoteursDOM.scroll(
            this.scrollPosition.left,
            this.scrollPosition.top,
          )
        }, 2000)
      }
    },
    showTimer: _.debounce(function () {
      this.showAfterSecond = true
    }, 1200),
  },

  computed: {
    ...mapGetters({
      scrollPosition: 'userNavigationState/getDossiersPromoteurScrollPosition',
      isScrollPositionSaved:
        'userNavigationState/isDossiersPromoteurScrollPosition',
      dossier_promoteur_selected:
        'dossierpromoteur/getDossierPromoteurSelected',
    }),
    responsable: {
      get() {
        return this.$store.getters['dossierpromoteur/getSelectedResponsable']
      },
      set(value) {
        this.$store.commit('dossierpromoteur/SET_SELECTED_RESPONSABLE', value)
      },
    },
    sort_list_terrains_by: {
      get() {
        return this.$store.getters['dossierpromoteur/getSortListTerrainsBy']
      },
      set(value) {
        this.$store.commit('dossierpromoteur/SET_SORT_LIST_TERRAINS_BY', value)
      },
    },
    sort_list_terrains_by_order: {
      get() {
        return this.$store.getters[
          'dossierpromoteur/getSortListTerrainsByOrder'
        ]
      },
      set(value) {
        this.$store.commit(
          'dossierpromoteur/SET_SORT_LIST_TERRAINS_BY_ORDER',
          value,
        )
      },
    },
  },
}
</script>
